<template>
  <div
    class="bg-white p-4"
    style="border-radius: 10px; border: solid 1px #e4e4e4"
  >
    <div
      class="
        flex flex-col
        lg:flex-row lg:justify-between lg:items-center
        gap-y-3
        lg:gap-y-0
      "
    >
      <div class="flex flex-row">
        <img
          class="
            object-cover
            border border-solid border-gray-200
            cursor-pointer
          "
          style="height: 120px; width: 110px; border-radius: 8px"
          onerror="javascript:this.src='/no-avatar.png'"
          :alt="`${doctor.title || 'Dr.'} ${doctor.firstName} ${
            doctor.lastName
          }`"
          :src="$checkAvatar(doctor.image)"
          @click="goToDoctorProfile(doctor)"
        />
        <div class="flex flex-col pl-4 mt-1">
          <h2
            class="text-lg font-EffraM text-black cursor-pointer"
            @click="goToDoctorProfile(doctor)"
          >
            <span class="capitalize">
              {{ doctor.title === "DR" ? "Dr." : "Pr." || "Dr." }}
            </span>

            {{ doctor.firstName }}
            {{ doctor.lastName }}
          </h2>
          <div
            class="flex flex-row item-center mt-3"
            v-if="doctor.specialities && doctor.specialities.length"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="none"
              viewBox="0 0 18 18"
            >
              <path
                fill="#767676"
                d="M6.429 3.214V4.5H5.143v2.572c0 1.42 1.15 2.57 2.571 2.57 1.421 0 2.572-1.15 2.572-2.57V4.5H9V3.214h1.929c.354 0 .643.288.643.643v3.215c0 1.91-1.39 3.497-3.215 3.803v1.018c0 1.243 1.008 2.25 2.25 2.25.963 0 1.784-.604 2.106-1.455-.673-.3-1.141-.975-1.141-1.76C11.572 9.864 12.434 9 13.5 9s1.929.863 1.929 1.929c0 .881-.592 1.624-1.4 1.854-.394 1.522-1.777 2.646-3.422 2.646-1.953 0-3.535-1.583-3.535-3.536v-1.018c-1.825-.306-3.215-1.892-3.215-3.803V3.857c0-.355.288-.643.643-.643H6.43zm7.071 7.072c-.355 0-.643.288-.643.643 0 .354.288.643.643.643.355 0 .643-.288.643-.643 0-.355-.288-.643-.643-.643z"
              />
            </svg>
            <div class="flex flex-wrap gap-x-1">
              <span
                class="text-sm font-EffraR"
                style="color: #767676"
                v-for="(spec, index) in doctor.specialities"
                :key="index"
              >
                <span
                  v-html="
                    doctor.specialities.length - 1 != index
                      ? `<strong>${spec.name}</strong>, `
                      : `<strong>${spec.name}</strong>`
                  "
                ></span>
              </span>
              <strong style="color: #767676" class="text-sm font-EffraR">{{
                " à " + doctor.cityName
              }}</strong>
            </div>
          </div>
          <span
            v-if="doctor.gender"
            class="font-EffraR flex gap-x-2 text-sm items-center pl-1 mt-1"
            style="color: #767676"
          >
            <i class="fas fa-neuter"></i>

            {{ doctor.gender == "FEMALE" ? "Femme" : "Homme" }}
          </span>
          <span
            v-if="doctor.experienceSince"
            class="font-EffraR flex gap-x-2 text-sm items-center pl-1 mt-1"
            style="color: #767676"
          >
            <i class="far fa-clock"></i>
            <strong>
              {{
                doctor.experienceSince
                  ? parseExperience(doctor.experienceSince)
                  : null
              }}
            </strong>
            {{ $t("patient.d_experience") }}
          </span>
          <span
            class="font-EffraR flex gap-x-2 text-sm items-center pl-1 mt-1"
            style="color: #767676"
          >
            <i class="fas fa-map-marker-alt"></i>

            {{ doctor.cityName }}
          </span>
        </div>
      </div>
      <button
        class="
          flex
          items-center
          w-full
          justify-center
          lg:w-auto
          gap-x-2
          bg-rosDok-ultra
          border-0
          text-white
          font-EffraM
          text-base
          px-5
          py-3
          rounded
          cursor-pointer
        "
        @click="goToDoctorProfile(doctor)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          fill="none"
          viewBox="0 0 18 18"
        >
          <path
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M14.25 3H3.75c-.828 0-1.5.672-1.5 1.5V15c0 .828.672 1.5 1.5 1.5h10.5c.828 0 1.5-.672 1.5-1.5V4.5c0-.828-.672-1.5-1.5-1.5zM12 1.5v3M6 1.5v3M2.25 7.5h13.5"
          />
        </svg>
        {{ $t("patient.take_appointment") }}
      </button>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { get, kebabCase } from "lodash";
export default {
  props: {
    doctor: {
      type: Object,
      required: true,
    },
  },
  methods: {
    parseExperience(date) {
      return moment(date).fromNow(true);
    },
    goToDoctorProfile(doctor) {
      const speciality = kebabCase(
        get(doctor, "specialities[0].name", "medecin-generaliste")
      ).toLowerCase();
      const city = kebabCase(get(doctor, "cityName", "maroc")).toLowerCase();
      this.$router.push({
        name: "profileDoctor",
        params: { slug: doctor.slug, speciality, city },
      });
    },
  },
};
</script>
